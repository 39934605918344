.navbar {
  background-color: #f68207 !important; /* Fondo naranja */
}

.navbar-brand {
  font-weight: bold;
  font-size: 1.5rem;
  display: flex;
  align-items: center;
  margin-left: 2rem; /* Mueve el logo dos espacios hacia la derecha */
}

.nav-link {
  color: white !important; /* Texto blanco */
  margin-right: 1rem;
  font-size: 1.1rem;
  position: relative;
}

/* Agregar la línea separadora entre los enlaces */
.nav-link:not(:last-child)::after {
  content: '';
  position: absolute;
  right: -0.5rem; /* Ajusta la posición horizontal de la línea */
  height: 24px;   /* Altura de la línea */
  width: 1px;     /* Grosor de la línea */
  background-color: white; /* Color de la línea separadora */
}

.nav-link:hover {
  color: #ffcc80 !important; /* Color al pasar el mouse */
}

.navbar-toggler {
  border-color: rgba(255, 255, 255, 0.5);
  margin-left: -0.5rem; /* Mueve la hamburguesa un espacio a la izquierda */
}

.navbar-toggler-icon {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 30 30' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='rgba(255, 255, 255, 0.5)' stroke-width='2' linecap='round' linejoin='round' d='M4 7h22M4 15h22M4 23h22'/%3E%3C/svg%3E");
}

/* Efecto flotante en pantallas pequeñas con fondo semi-transparente */
@media (max-width: 991px) {
  .navbar-collapse {
    position: absolute;
    top: 100%;
    left: 0;
    right: 0;
    background-color: rgba(246, 130, 7, 0.85); /* Fondo naranja semi-transparente */
    padding: 1rem;
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2); /* Sombra flotante */
    z-index: 9;
    border-radius: 0 0 10px 10px;
    transition: transform 0.3s ease, opacity 0.45s ease; /* Transiciones suaves */
    transform: translateY(-20px); /* Comienza un poco fuera de pantalla */
    opacity: 0;
    visibility: hidden;
  }

  .navbar-collapse.show {
    transform: translateY(0); /* Despliega el menú */
    opacity: 1;
    visibility: visible;
  }

  .navbar-nav {
    flex-direction: column;
    align-items: flex-start;
  }

  .nav-link {
    margin-right: 0;
    margin-bottom: 1rem;
    width: 100%;
    text-align: left;
    border-bottom: 1px solid rgba(255, 255, 255, 0.2); /* Separador entre enlaces */
  }

  .nav-link:last-child {
    border-bottom: none;
  }
}
