/* General styles for the Services component */
body {
    background-color: #1f1f1f; /* Dark background for a modern look */
    color: #f0f0f0; /* Light text color */
    font-family: 'Roboto', sans-serif; /* Clean and modern font */
  }
  
  h1 {
    font-family: 'Orbitron', sans-serif; /* Futuristic font */
    font-size: 2.5rem;
    color: #f5a107; /* Neon-like color */
    text-transform: uppercase;
    letter-spacing: 2px;
   /*  text-shadow: 0 0 10px #00ffcc, 0 0 20px #00ffcc; /* Neon glow effect */ 
    margin-bottom: 40px;
  }
  
  /* Card Styles */
  .card {
    background-color: #2a2a2a; /* Dark card background */
    border: none; /* Remove default border */
    border-radius: 10px;
    overflow: hidden;
    transition: transform 0.3s ease, box-shadow 0.3s ease;
    box-shadow: 0 0 15px rgba(0, 0, 0, 0.5);
  }
  
  .card:hover {
    transform: scale(1.05); /* Slightly enlarges card on hover */
    box-shadow: 0 0 30px rgba(0, 255, 204, 0.8); /* Neon glow on hover */
  }
  
  .card-img-top {
    height: 200px;
    object-fit: cover; /* Ensures the image covers the area */
    filter: grayscale(100%); /* Black & white images for a tech effect */
    transition: filter 0.3s ease;
  }
  
  .card-img-top:hover {
    filter: grayscale(0); /* Reverts to color on hover */
  }
  
  /* Card Title */
  .card-title {
    font-family: 'Orbitron', sans-serif;
    font-size: 1.5rem;
    color: #00ffcc;
    text-transform: uppercase;
    letter-spacing: 1px;
    margin-bottom: 15px;
  }
  
  /* Card Text */
  .card-text {
    font-family: 'Roboto', sans-serif;
    font-size: 1rem;
    color: #dcdcdc; /* Slightly lighter text */
    line-height: 1.6;
  }
  
  ul {
    padding-left: 20px;
  }
  
  ul li {
    font-size: 0.9rem;
    margin-bottom: 10px;
  }
  
  ul li::before {
    content: '• '; /* Adds a dot before list items */
    color: #00ffcc; /* Same neon color for bullet points */
  }
  
  /* Row styling */
  .row {
    margin-bottom: 40px;
  }
  
  /* Responsive Design */
  @media (max-width: 768px) {
    .card {
      margin-bottom: 20px;
    }
  
    h1 {
      font-size: 2rem;
    }
  
    .card-title {
      font-size: 1.25rem;
    }
  
    .card-text {
      font-size: 0.9rem;
    }
  }
  