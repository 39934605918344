.home {
  color: #1f1f1f;
  padding: 50px 0;
}

.hero-section {
  display: flex;
  flex-direction: column;
  justify-content: center; /* Ensure content is centered vertically by default */
  align-items: flex-start; /* Align items to the left */
  text-align: left; 
  padding: 100px 0;
  background-image: url('https://suriyaco.ar/images/gis_mejor.jpeg'); /* URL de la imagen de fondo */
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  color: white;
  
}

@media (max-width: 768px) {
  .hero-section {
    padding: 30px 0;
    text-align: left; 
    font-size: 1rem;
    color: rgba(77, 186, 219, 0.5); /* Ajusta la opacidad para hacer el texto más transparente */
    justify-content: flex-end; /* Alinea el contenido hacia abajo */
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
  }

  .hero-section h1 {
    font-size: 3rem;
    font-weight: bold;
    margin-bottom: 20px;
    opacity: 0.5;
  }

  .hero-section p {
    font-size: 1.2rem;
  }
}

@media (max-width: 480px) {
  .hero-section {
    padding: 30px 0;
  }

  .hero-section h1 {
    font-size: 1.5rem;
  }

  .hero-section p {
    font-size: 1rem;
  }
}

.main-content {
  padding: 20px;
  background: rgba(255, 255, 255, 0.9);
  color: black;
  border-radius: 10px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
}

.mission-section {
  margin-bottom: 40px;
}

.cards-section {
  margin-bottom: 40px;
}

.image-card {
  margin-bottom: 20px;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.image-card:hover {
  transform: translateY(-10px);
  box-shadow: 0 15px 30px rgba(0, 0, 0, 0.3);
}

.sidebar {
  padding: 20px;
  background: rgba(255, 255, 255, 0.9);
  color: black;
  border-radius: 10px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
}

.news-item {
  margin-bottom: 20px;
  cursor: pointer;
}

.news-image, .news-video {
  width: 100%;
  height: auto;
  margin-bottom: 10px;
}
