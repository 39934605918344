/* Estilos generales para el contenedor */
.contact-container {
    max-width: 600px;
    margin: 0 auto;
    padding: 20px;
    background-color: #f9f9f9;
    border-radius: 8px;
    box-shadow: 0 0 15px rgba(0, 0, 0, 0.1);
  }
  
  /* Título del formulario */
  .contact-container h2 {
    text-align: center;
    margin-bottom: 20px;
    color: #333;
    font-family: 'Arial', sans-serif;
  }
  
  /* Estilos generales para los inputs */
  .contact-container .form-group {
    margin-bottom: 15px;
  }
  
  .contact-container label {
    font-weight: bold;
    margin-bottom: 5px;
    display: block;
    color: #555;
  }
  
  .contact-container input[type="text"],
  .contact-container input[type="email"],
  .contact-container textarea {
    width: 100%;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 4px;
    font-size: 16px;
    box-sizing: border-box;
    transition: all 0.3s ease;
  }
  
  /* Estilos para los campos de entrada en foco */
  .contact-container input[type="text"]:focus,
  .contact-container input[type="email"]:focus,
  .contact-container textarea:focus {
    border-color: #007bff;
    outline: none;
    box-shadow: 0 0 8px rgba(0, 123, 255, 0.3);
  }
  
  /* Estilos para el textarea */
  .contact-container textarea {
    resize: vertical;
    min-height: 120px;
  }
  
  /* Botón de envío */
  .contact-container .btn {
    display: block;
    width: 100%;
    padding: 10px;
    background-color: #007bff;
    color: white;
    font-size: 18px;
    font-weight: bold;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  .contact-container .btn:hover {
    background-color: #0056b3;
  }
  
  /* Responsividad */
  @media (max-width: 768px) {
    .contact-container {
      padding: 15px;
    }
  
    .contact-container h2 {
      font-size: 24px;
    }
  
    .contact-container input[type="text"],
    .contact-container input[type="email"],
    .contact-container textarea {
      font-size: 14px;
    }
  
    .contact-container .btn {
      font-size: 16px;
    }
  }
  