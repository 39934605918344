/* Estilos generales de la página */
.equipment-page {
    max-width: 1200px;
    margin: 0 auto;
    padding: 20px;
    font-family: 'Arial', sans-serif;
    text-align: center;
  }
  
  .equipment-page h1 {
    font-size: 36px;
    margin-bottom: 40px;
    color: #f9f9f9;
  }
  
  /* Estilos para la lista de equipamiento */
  .equipment-list {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
    gap: 20px;
  }
  
  .equipment-card {
    background-color: #f9f9f9;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    transition: transform 0.3s ease;
  }
  
  .equipment-card:hover {
    transform: translateY(-10px);
  }
  
  .equipment-image {
    width: 100%;
    height: auto;
    border-radius: 8px;
    margin-bottom: 20px;
  }
  
  .equipment-card h2 {
    font-size: 24px;
    color: #007bff;
  }
  
  .equipment-card p {
    font-size: 16px;
    color: #555;
    line-height: 1.6;
  }
  
  /* Responsividad */
  @media (max-width: 768px) {
    .equipment-page h1 {
      font-size: 28px;
    }
  
    .equipment-card h2 {
      font-size: 20px;
    }
  
    .equipment-card p {
      font-size: 14px;
    }
  }
  