.slider-container {
    max-width: 900px;
    margin: 0 auto;
    padding: 20px;
  }
  
  .slide {
    position: relative;
    text-align: center;
  }
  
  .slide-image {
    width: 100%;
    height: auto;
    border-radius: 8px;
  }
  
  .slide-text {
    position: absolute;
    bottom: 20px;
    left: 20px;
    right: 20px;
    background-color: rgba(0, 0, 0, 0.6);
    color: white;
    padding: 15px;
    border-radius: 8px;
  }
  
  .slide-text h3 {
    margin: 0 0 10px;
    font-size: 24px;
  }
  
  .slide-text p {
    margin: 0;
    font-size: 16px;
  }
  
  /* Responsividad */
  @media (max-width: 768px) {
    .slide-text h3 {
      font-size: 20px;
    }
    .slide-text p {
      font-size: 14px;
    }
  }
  