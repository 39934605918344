.counter-container {
    position: fixed;
    top: 10px;
    right: 10px;
    background-color: #ff9800;
    color: white;
    padding: 10px;
    border-radius: 5px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  }
  
  .folder-list {
    list-style-type: none;
    padding: 0;
  }
  
  .folder-item {
    margin-bottom: 10px;
  }
  
  .folder-icon {
    margin-right: 5px;
  }
  
  .image-grid {
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
    margin-top: 10px;
  }
  
  .image-container {
    width: 100px;
    height: 100px;
  }
  
  .small-image {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  